.inicio-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inicio-next-page {
  width: 5vw;
  height: 100vh;
  right: 0;
  top: 0;
  background-color: rgba(1, 1, 1, 0.03);
  z-index: 1;
  backdrop-filter: blur(3px);
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding-right: 15px;
}

.inicio-prev-page {
  width: 5vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(1, 1, 1, 0.03);
  z-index: 1;
  backdrop-filter: blur(3px);
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding-left: 15px;
  z-index: 9999;
}

.inicio-next {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9990;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: red;
  cursor: pointer;
}

.inicio-next-page:hover {
  opacity: 1;
}

.inicio-prev-page:hover {
  opacity: 1;
}

.inicio-arrow {
  width: 40px;
  opacity: 0;
  transform: rotate(90deg) translateY(25px);
  position: relative;
  transition: all 0.5s;
}

.inicio-next-page:hover .inicio-arrow {
  opacity: 1;
  transform: rotate(90deg);
}

.inicio-image-piece {
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
  animation: inicio-image-animation 0.42s ease-in;
}

@keyframes inicio-image-animation {
  100% {
    opacity: 1;
    transform: scale(1);
  }

  0% {
    opacity: 0;
    transform: scale(0.98);
  }
}

@media (max-width: 1300px) {
  .cover-video {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.share-wrapper {
  position: absolute;
  top: 0;
  left: 35%;
  height: 60px;
  width: 100px;
  background-color: rgba(68, 68, 68, 0.26);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 12px;
  border-radius: 6px;
  /* box-shadow: 0px 20px 28px -12px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 10px 10px 58px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 58px 0px rgba(0, 0, 0, 0.75); */
  animation: share-animation 0.3s ease-out;
  z-index: 9999;
}

.share-icon {
  background-color: rgba(255, 255, 255, 0.26);
  color: white;
  font-size: 1rem;
  padding: 6px;
  border-radius: 9px;
  transition: all 0.2s;
  cursor: pointer;
}

.share-icon:hover {
  background-color: rgba(255, 255, 255, 0.46);
  transform: scale(1.05);
}

.linkedin-icon {
  width: 19px;
  background-color: rgba(255, 255, 255, 0.26);
  color: white;
  font-size: 1rem;
  padding: 6px;
  border-radius: 9px;
  transition: all 0.2s;
  cursor: pointer;
}

.linkedin-icon:hover {
  background-color: rgba(255, 255, 255, 0.46);
  transform: scale(1.05);
}

@keyframes share-animation {
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  0% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.app .pages-wrapper {
  background-color: black;
}

.inicio-wrapper {
  height: 100vh;
  background-color: black;
}

.chapter-box {
  width: 88.3%;
  height: 9.5%;
  left: 49.6%;
  transform: translateX(-50%);
  position: absolute;
  background-color: rgb(255, 255, 255);
  z-index: 9999;
  transition: all 0.2s;
  opacity: 0;
}

.chapter-0-box {
  top: 20.5%;
}
.chapter-1-box {
  top: 30.5%;
}
.chapter-2-box {
  top: 40.5%;
}
.chapter-3-box {
  top: 50.5%;
}
.chapter-4-box {
  top: 60.5%;
}
.chapter-5-box {
  top: 70.5%;
}
.chapter-6-box {
  top: 80.5%;
}

.control-panel-box {
  width: 100%;
  height: 15%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0%;
  background-color: rgb(255, 255, 255);
  transition: all 0.2s;
  opacity: 0;
  display: none;
}

.panel-body-index {
  z-index: 9999;
}

.chapter-0-box:hover {
  opacity: 0.1;
}

.chapter-1-box:hover {
  opacity: 0.1;
}

.chapter-2-box:hover {
  opacity: 0.1;
}

.chapter-3-box:hover {
  opacity: 0.1;
}

.chapter-4-box:hover {
  opacity: 0.1;
}

.chapter-5-box:hover {
  opacity: 0.1;
}

.chapter-6-box:hover {
  opacity: 0.1;
}

.prev-page-box {
  width: 3%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9999;
  transition: all 0.2s;
  opacity: 1;
}

.next-page-box {
  width: 3%;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  z-index: 9999;
  transition: all 0.2s;
  opacity: 1;
}

.page-2-arrow {
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  width: 23px;
  right: 15px;
  transition: all 0.2s;
}

.next-page-box:hover .page-2-arrow {
  opacity: 1;
}

.page-2-left-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: 23px;
  transition: all 0.2s;
  opacity: 0;
  left: 15px;
}

.prev-page-box:hover .page-2-left-arrow {
  opacity: 1;
}

/* page 11 click boxes */
.prev-page-box-11 {
  width: 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  background-color: red;
  z-index: 999;
  transition: all 0.2s;
  border-radius: 8px;
  opacity: 0;
}

.next-page-box-11 {
  width: 50%;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  background-color: rgba(255, 0, 0, 0.198);
  z-index: 999;
  transition: all 0.2s;
  opacity: 0;
  border-radius: 8px;
}

.page-11-box {
  width: 3.5%;
  height: 4%;
  left: 28%;
  position: absolute;
  top: 88.5%;
  background-color: rgb(255, 255, 255);

  z-index: 9999;
  transition: all 0.2s;
  opacity: 0;
  border-radius: 8px;
  cursor: pointer;
}

.page-11-box:hover {
  opacity: 0.5;
}

.page-18-box {
  width: 5%;
  height: 5%;
  left: 90%;
  position: absolute;
  top: 85%;
  background-color: rgb(31 40 49);

  z-index: 9999;
  transition: all 0.2s;
  opacity: 0;
  border-radius: 8px;
  cursor: pointer;
}
.page-18-box:hover {
  opacity: 0.5;
}
.page-33-box {
  width: 3.5%;
  height: 5%;
  left: 91%;
  position: absolute;
  top: 80%;
  background-color: rgb(0, 0, 0);

  z-index: 9999;
  transition: all 0.2s;
  opacity: 0;
  border-radius: 8px;
  cursor: pointer;
}
.page-33-box:hover {
  opacity: 0.5;
}

.page-43-box {
  width: 3.5%;
  height: 5%;
  left: 91%;
  position: absolute;
  top: 85.5%;
  background-color: rgb(0, 0, 0);

  z-index: 9999;
  transition: all 0.2s;
  opacity: 0;
  border-radius: 8px;
  cursor: pointer;
}
.page-43-box:hover {
  opacity: 0.5;
}

.page-69-box {
  width: 3.5%;
  height: 3%;
  right: 74.3%;
  position: absolute;
  bottom: 80%;
  background-color: green;
  opacity: 0;
  z-index: 9999;
  transition: all 0.2s;
  border-radius: 8px;
  cursor: pointer;
  background-color: #59e228;
}

.page-69-box:hover {
  opacity: 0.5;
}

.page-55-box {
  width: 3.5%;
  height: 5%;
  left: 93.4%;
  position: absolute;
  top: 85.8%;
  background-color: #503ece;

  z-index: 9999;
  transition: all 0.2s;
  opacity: 0;
  border-radius: 8px;
  cursor: pointer;
}

.page-55-box:hover {
  opacity: 0.5;
}

.link-wrapper-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-wrapper {
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  object-fit: contain;
  display: flex;
  align-items: center;
  /* background-color: red;
  opacity: 0.4; */
}

/* .link-placeholder {
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
  animation: image-animation 0.22s ease-in; 
  opacity: 0.4;
} */

.app-control-arrow-back {
  position: absolute;
  left: 30px;
  width: 29px;
  top: 50%;
  z-index: 9999;
  transform: rotate(-90deg) translateY(-20%);
  opacity: 0;
  transition: all 0.4s;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 5px;
  border-radius: 4px;
}

.app-control-arrow-foward {
  position: absolute;
  right: 30px;
  width: 29px;
  top: 50%;
  z-index: 9999;
  transform: rotate(90deg) translateY(-20%);
  opacity: 0;
  transition: all 0.4s;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 5px;
  border-radius: 4px;
}
.prev-page-box:hover .app-control-arrow-back {
  opacity: 1;
  transform: rotate(-90deg) translateY(-50%);
}

.next-page-box:hover .app-control-arrow-foward {
  opacity: 1;
  transform: rotate(90deg) translateY(-50%);
}

@media (max-width: 845px) {
  .link-wrapper {
    position: absolute;
  }
}

.lower-panel-container {
  position: relative;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  overflow: hidden !important;
}

.small-pic-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.lower-button-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lower-pannel-button {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  background-color: #4c4c4c5a;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  transition: all 0.2s;
  cursor: pointer;
}

.lower-pannel-button:hover {
  transform: scale(1.05);
  background-color: #5d5d5d;
}

.share-icon-panel {
  width: 10px;
}

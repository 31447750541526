.small-pic {
  width: 21px;
  transition: all 0.09s;
  transform: scale(1);
  object-fit: cover;
  padding-left: 3px;
}
.small-pic-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: auto;
}

@media (max-width: 1300px) {
  .small-pic {
    width: 14px;
  }
  .small-pic-area {
    width: 12px;
  }
}

.small-pic:hover {
  transform: scale(1.5);
  z-index: 999;
}

.small-pick-clicker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chapter-line-wrapper {
  position: absolute;
  width: 100%;
  top: -17px;
}

.chapter-line {
  height: 1px;
  width: 100%;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.chapter-line-fill {
  background-color: white;
  width: 2%;
  height: 1px;
  opacity: 0.1;
  transition: width 0.5s, opacity 0.5s;
}

.chapter-line-active .chapter-line-fill {
  background-color: white;
  opacity: 1;
  width: 100%;
}

.chapter-square {
  height: 7px;
  width: 7px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 4px transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  transition: background-color 0.5s, transform 0.5s;
}

.chapter-square-active {
  background-color: #ffffff;
}

.chapter-line-half-wrapper {
  position: absolute;
  width: 10%;
  top: -17px;
  display: flex;
  justify-content: space-between;
}

.chapter-half-line {
  height: 1px;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: grey;
}

.chapter-half-line-active .chapter-line-fill {
  background-color: white;
  opacity: 1;
  width: 100%;
}

.chapter-square-title {
  color: rgb(255, 255, 255);
  position: absolute;
  top: -21px;
  font-size: 0.8rem;
  width: 500px;
  left: -100px;
  opacity: 0;
  transform: translateY(5px);
  transition: all 0.4s;
}

.chapter-square-five-title {
  color: rgb(255, 255, 255);
  position: absolute;
  top: -21px;
  font-size: 0.8rem;
  width: 500px;
  left: -58px;
  opacity: 0;
  transform: translateY(5px);
  transition: all 0.4s;
}

.chapter-square:hover .chapter-square-title {
  opacity: 1;
  transform: translateY(0px);
}

.chapter-square:hover .chapter-square-five-title {
  opacity: 1;
  transform: translateY(0px);
}

.gallery-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  background-color: white;
}

.gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  animation: full-galery-animation 0.6s;
  max-width: 95%;
}

.gallery-pic-wrapper {
  width: fit-content;
  height: fit-content;
  background-color: rgb(236, 236, 236);
  margin: 14px;
  -webkit-box-shadow: 5px 5px 42px -10px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 15px 42px -5px rgba(0, 0, 0, 0.19);
  width: 400px;
  height: 224.883px;
}

.gallery-pic {
  width: 400px;
  transition: transform 0.17s, opacity 0.12s;
  cursor: pointer;
  animation: galery-animation 0.7s ease-in;
}

.gallery-back-mobile {
  display: none;
}

@media (max-width: 1400px) {
  .gallery-pic-wrapper {
    width: 90%;
    height: auto;
  }
  .gallery-pic {
    width: 100%;
    height: auto;
  }

  .gallery-back {
    display: none;
  }

  .gallery-back-mobile {
    display: block;
    text-align: center;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    margin: 20px 0;
  }
}

@media (max-width: 650px) {
  .gallery-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.gallery-pic:hover {
  transform: scale(1.03);
  opacity: 0.8;
}

@keyframes galery-animation {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.gallery-back {
  transform: rotate(-90deg);
  position: fixed;
  top: 50%;
  left: -5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.5s;
  background-color: white;
}

.gallery-arrow {
  margin-left: 7px;
  transform: scale(0.8);
}

.gallery-back:hover {
  opacity: 0.5;
}

@keyframes full-galery-animation {
  100% {
    transform: translateX(0);
    opacity: 1;
  }

  0% {
    transform: translateX(50px);
    opacity: 0;
  }
}

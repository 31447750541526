.control-screen {
  height: 100vh;
  width: 100%;
  position: relative;
  animation: full-panel-animation 0.5s ease-in;
  overflow: hidden !important;
}

.panel-body {
  height: 15vh;
  width: 100%;
  background-color: rgba(68, 68, 68, 0.26);
  border-radius: 2px;
  transition: all 3s;
  opacity: 1;
  animation: panel-animation 0.3s ease-in;
  backdrop-filter: blur(6px);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow: hidden !important;
}

.panel-wrapper:hover .panel-body {
  transform: translateY(0);
  animation-play-state: running;
  display: block;
}

@media (max-width: 999px) {
  .panel-body {
    display: none;
  }
}

.half-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.half-back {
  height: 100vh;
  width: 50%;
  position: relative;
}

.control-arrow-back {
  position: absolute;
  left: 30px;
  width: 29px;
  top: 50%;
  z-index: 9999;
  transform: rotate(-90deg) translateY(-20%);
  opacity: 0;
  transition: all 0.4s;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 5px;
  border-radius: 4px;
}

.control-arrow-foward {
  position: absolute;
  right: 30px;
  width: 29px;
  top: 50%;
  z-index: 9999;
  transform: rotate(90deg) translateY(-20%);
  opacity: 0;
  transition: all 0.4s;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 5px;
  border-radius: 4px;
}
.half-back:hover .control-arrow-back {
  opacity: 1;
  transform: rotate(-90deg) translateY(-50%);
}

.half-forward:hover .control-arrow-foward {
  opacity: 1;
  transform: rotate(90deg) translateY(-50%);
}

.half-back-low {
  height: 15vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.half-forward {
  height: 100vh;
  width: 50%;
  position: relative;
}

.half-forward-low {
  height: 15vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.mobile-control-panel {
  display: none;
  position: absolute;
}

.video {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  object-fit: contain;
  display: flex;
  align-items: center;
}

@media (max-width: 320px) {
  .mobile-control-panel {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 210px;
    padding: 20px 0;
    width: 130px;
    border-radius: 2px;
    justify-content: space-between;
    z-index: 9999;
    align-items: center;
  }
}

@media (max-width: 999px) {
  .control-screen {
    height: 100%;
    width: 100%;
    position: relative;
    animation: full-panel-animation 0.5s ease-in;
  }

  .mobile-control-panel {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 210px;
    padding: 20px 0;
    width: 120px;
    border-radius: 2px;
    justify-content: space-between;
    z-index: 9998;
    align-items: center;
  }

  .mobile-pannel-button {
    color: white;
    border-radius: 24px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4c;
    backdrop-filter: blur(6px);
    font-size: 1rem;
  }

  .mobile-control-panel-icon {
    width: 19px;
  }
}

@keyframes panel-animation {
  100% {
    opacity: 1;
    transform: translateY(0);
  }

  0% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes full-panel-animation {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

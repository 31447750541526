body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  color: grey;
  background-color: black;
}

::-webkit-scrollbar {
  display: none;
}
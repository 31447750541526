.image-container {
  position: relative;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.image-piece {
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
  animation: inicio-image-animation 0.42s ease-in;
}

.image-piece-show-false {
  opacity: 0;
  transform: scale(0.97) translateX(5%);
  transition: opacity 0.5s, transform 0.5s;
}

.image-piece-show-true {
  opacity: 1;
  transform: scale(1) translateX(-0%);
  transition: opacity 0.5s;
}

.image-piece-show-left-false {
  opacity: 0;
  transform: scale(0.97) translateX(-5%);
  transition: opacity 0.5s, transform 0.5s;
}

.image-piece-show-left-true {
  opacity: 1;
  transform: scale(1) translateX(-0%);
  transition: opacity 0.5s;
}

.background {
  background-color: white;
}

@media (max-width: 950px) {
  .video-safari {
    max-height: 550px;
    max-width: 100vw;
  }
}

@media (max-width: 850px) {
  .video-safari {
    max-height: 490px;
    max-width: 100vw;
  }
}

@media (max-width: 750px) {
  .video-safari {
    max-height: 450px;
    max-width: 100vw;
  }
}

@media (max-width: 650px) {
  .video-safari {
    max-height: 380px;
    max-width: 100vw;
  }
}

@media (max-width: 550px) {
  .video-safari {
    max-height: 310px;
    max-width: 100vw;
  }
}

@media (max-width: 450px) {
  .video-safari {
    max-height: 240px;
    max-width: 100vw;
  }
}

@media (max-width: 350px) {
  .video-safari {
    max-height: 180px;
    max-width: 100vw;
  }
}

@media (max-width: 768px) {
  .image-container .image-piece.video-active {
    display: none;
  }
}

@media (max-height: 768px) and (orientation: landscape) {
  .image-container .image-piece.video-active {
    display: none;
  }
}
